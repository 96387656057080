export const ADD_LOCAL_TRACK = 'ADD_LOCAL_TRACK';
export const UPDATE_LOCAL_TRACK = 'UPDATE_LOCAL_TRACK';
export const REMOVE_LOCAL_TRACK = 'REMOVE_LOCAL_TRACK';
export const LOCAL_TRACK_ACTION = 'LOCAL_TRACK_ACTION';

export const ADD_CONFERENCE = 'ADD_CONFERENCE';
export const ADD_CONNECTION = 'ADD_CONNECTION'
export const REMOVE_CONFERENCE = 'REMOVE_CONFERENCE';
export const GET_CONFERENCE = 'GET_CONFERENCE';
export const SET_DEVICES = "SET_DEVICES";

export const CREATE_LOCAL_TRACKS = "CREATE_LOCAL_TRACKS";
export const REMOVE_CONNECTION = "REMOVE_CONNECTION";


//connection
export const CONNECTION_ESTABLISHED = "CONNECTION_ESTABLISHED";
export const CONNECTION_DISCONNECTED =  "CONNECTION_DISCONNECTED";
export const CONNECTION_FAILED =  "CONNECTION_FAILED";

// confernece
export const CONFERENCE_JOINED = "CONFERENCE_JOINED";
export const CONFERENCE_LEFT = "CONFERENCE_LEFT";
export const CONFERENCE_FAILED = "CONFERENCE_FAILED";
export const CONFERENCE_ERROR = "CONFERENCE_ERROR";

// track
export const TRACK_ADDED = "TRACK_ADDED";
export const TRACK_REMOVED = "TRACK_REMOVED";
export const TRACK_MUTE_CHANGED = "TRACK_MUTE_CHANGED";

export const CONNECTION_DISCONNECT = "disconnect";


export const REMOVE_ALL_LOCAL_TRACK = "REMOVE_ALL_LOCAL_TRACK";
export const PASSWORD_REQUIRED = "PASSWORD_REQUIRED";


export const LOCAL_TRACK_MUTE_CHANGED = "LOCAL_TRACK_MUTE_CHANGED";
export const REMOVE_PARTICIPANT = "REMOVE_PARTICIPANT";
export const SELECT_PARTICIPANT = "SELECT_PARTICIPANT";

export const ADD_REMOTE_TRACK = "ADD_REMOTE_TRACK";
export const UPDATE_REMOTE_TRACK = "UPDATE_REMOTE_TRACK";
export const REMOVE_REMOTE_TRACK = "REMOVE_REMOTE_TRACK";
export const REMOVE_ALL_REMOTE_TRACK = "REMOVE_ALL_REMOTE_TRACK";
export const REMOTE_TRACK_MUTE_CHANGED = "REMOTE_TRACK_MUTE_CHANGED";

export const SET_LAYOUT  = "SET_LAYOUT";
export const SET_FULLSCREEN_MODE = "SET_FULLSCREEN_MODE";
export const CLEAR_ALL = "CLEAR_ALL";
export const SET_ACTIVE_CONTROL = "SET_ACTIVE_CONTROL";

export const SET_PROFILE = "SET_PROFILE";
export const ADD_MESSAGE = "ADD_MESSAGE";


export const SET_MICROPHONE = "SET_MICROPHONE";
export const SET_SPEAKER = "SET_SPEAKER";
export const SET_CAMERA = "SET_CAMERA";

export const UNREAD_MESSAGES = "UNREAD_MESSAGES";
export const SET_PIN_PARTICIPANT = "SET_PIN_PARTICIPANT";
export const SET_PRESENTER = "SET_PRESENTER";
export const ADD_COLOR = "ADD_COLOR";
export const REMOVE_COLOR = "REMOVE_COLOR";
export const SET_AUDIO_LEVEL = "SET_AUDIO_LEVEL";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const SHOW_SNACKBAR = "SHOW_SNACKBAR";

export const SET_GOOGLE_API_STATE = "SET_GOOGLE_API_STATE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const SET_MEETING_TITLE  = "SET_MEETING_TITLE";
export const SET_DISCONNECTED  = "SET_DISCONNECTED";

export const SET_PRESENTATION_TYPE = "SET_PRESENTATION_TYPE";
export const SET_HAND_RAISE = "SET_HAND_RAISE";
export const SET_MODERATOR = "SET_MODERATOR";

export const ADD_SUBTITLE = "ADD_SUBTITLE";
export const PARTICIPANT_LEFT = "PARTICIPANT_LEFT";

export const SET_RESOLUTION = "SET_RESOLUTION"
export const SET_USER_RESOLUTION = "SET_USER_RESOLUTION";

export const TOGGLE_PIP = "TOGGLE_PIP";
export const UPDATE_PIP_STATUS = "UPDATE_PIP_STATUS";
export const SET_PICTURE_IN_PICTURE = 'SET_PICTURE_IN_PICTURE';
export const TOGGLE_COLLABORATION = 'TOGGLE_COLLABORATION';
